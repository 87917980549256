const menuItems = [
    {
      title: "Home",
      url: "#",
      cName: "nav-links"
    },
    {
      title: "Blog",
      url: "#",
      cName: "nav-links"
    },
    {
      title: "Movies",
      url: "#",
      cName: "nav-links"
    },
    {
      title: "News",
      url: "#",
      cName: "nav-links"
    },
    {
      title: "Help",
      url: "#",
      cName: "nav-links-mobile"
    }
  ];
  
  export default menuItems;
  