import React, { useState } from "react";
import Button from "./Button";
import menuItems from "./MenuItems";
import "./Navbar.css";
import logo from "../Navbar/LogoLong-removebg-preview.png";

const Navbar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <nav className="navbar">
      <h1 className="navbar-logo" onClick={closeMobileMenu}>
        <i className="logo">
        <img src={logo} alt="" />
        </i>
      </h1>
      <div className="menu-icon" onClick={handleClick}>
        <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
      </div>
      <ul className={click ? "nav-menu active" : "nav-menu"}>
        {menuItems.map((item, index) => (
          <li key={index}>
            <a href={item.url} className={item.cName} onClick={closeMobileMenu}>
              {item.title}
            </a>
          </li>
        ))}
      </ul>
      {/* <Button buttonStyle="btn-outline" buttonSize="btn-medium">SIGN UP</Button> */}
    </nav>
  );
};

export default Navbar;
