import React from 'react';
import "../FooterMy/Footer.css";
import logo3a from '../FooterMy/LogoThree.png';

const FooterMy = () => {
  return (
    <div>
      <footer className="footer-main">
        <div className="FooterContainer">
          <div className="footer-content">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                <div className="footer-logo-widget footer-widget">
                  <figure className="footer-logo-box">
                    <a href="#">
                      <img className='Footerimg' src={logo3a} alt="Logo" />
                    </a>
                  </figure>
                  <div className="footer-text">
                    <p>We are the Film Producers Council, dedicated to revitalizing the
                theatre-going experience. Our mission is to bring audiences back
                to theatres instead of OTT platforms. We believe in the unique
                and immersive experience only theatres can offer. Enjoy exciting
                cashback rewards on movie tickets through our latest initiative.</p>
                  </div>
                  {/* <ul className="footer-social">
                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="#"><i className="fab fa-vimeo-v"></i></a></li>
                    <li><a href="#"><i className="fab fa-google-plus-g"></i></a></li>
                  </ul> */}
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 offset-lg-2 footer-column">
                <div className="footer-service-widget footer-widget">
                  <div className="footer-title">Services</div>
                  <ul className="footer-list">
                    <li><a href="#">Home</a></li>
                    <li><a href="#">Blog</a></li>
                    <li><a href="#">Movies</a></li>
                    <li><a href="#">News</a></li>
                    <li><a href="#">Help</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 footer-widget">
                <div className="footer-contact-widget footer-widget">
                  <div className="footer-title">Contacts</div>
                  <div className="footer-text">
                    <p>
                      Company address:<br />
                      Ground Floor, PRABHADEVI INDUSTRIAL ESTATE, Maxis Healthcare Unit No. 12, 19/21, Swatantryaveer Savarkar Rd, opp. Siddhivinayak Temple, Prabhadevi, Mumbai, Maharashtra 400025
                    </p>
                    <p>+91 7799666884</p>
                    <p>filmproducers@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 column">
              <div className="footer-copyright">
                <a href="#">R.Tech Solution's</a> &copy; 2024-25 All Rights Reserved
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 column">
              <ul className="footer-nav">
                <li><a href="#">Terms of Service</a></li>
                <li><a href="#">Privacy Policy</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterMy;
