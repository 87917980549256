import React, { useState } from "react";
import Rating from "react-rating"; // Make sure to install react-rating if not already done
import "@fortawesome/fontawesome-free/css/all.css";
import "./FormData.css";

const FormDataProd = () => {
  const scriptURL =
    "https://script.google.com/macros/s/AKfycbzi5rXLCSNGr9qOvY_vdKZJ-H_tSEmvvLwG4XsI5eilroNeVRZB329s1rkZBcwc-0e0/exec"; // Replace with your actual URL
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    movieName: "",
    language: "",
    tickets: "1",
    phoneNumber: "",
    totalTicketPrice: "",
    discountedPrice: "",
    image: null,
    imagePreview: null,
    persons: [],
    rating: 0,
    reviewMessage: "",
  });
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [cashbackPercentage, setCashbackPercentage] = useState(0);
  const [cashbackClicked, setCashbackClicked] = useState(false);
  const [showCashbackCard, setShowCashbackCard] = useState(false);
  const [errors, setErrors] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePersonNameChange = (e, index) => {
    const persons = [...formData.persons];
    persons[index] = { ...persons[index], name: e.target.value };
    setFormData({ ...formData, persons });
  };

  const handlePersonNumberChange = (e, index) => {
    const persons = [...formData.persons];
    persons[index] = { ...persons[index], number: e.target.value };
    setFormData({ ...formData, persons });
  };

  const showModal = (e) => {
    e.preventDefault();
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const generatePersonFields = () => {
    let fields = [];
    for (let i = 1; i < formData.tickets; i++) {
      fields.push(
        <div key={i}>
          <label htmlFor={`personName${i}`} className="form-label">
            Person {i + 1} Name
          </label>
          <input
            type="text"
            id={`personName${i}`}
            name={`personName${i}`}
            className={`form-control mb-2 ${
              errors[`personName${i}`] ? "is-invalid" : ""
            }`}
            onChange={(e) => handlePersonNameChange(e, i)}
            required
          />
          <label htmlFor={`personNumber${i}`} className="form-label">
            Person {i + 1} Number
          </label>
          <input
            type="tel"
            id={`personNumber${i}`}
            name={`personNumber${i}`}
            className={`form-control mb-3 ${
              errors[`personNumber${i}`] ? "is-invalid" : ""
            }`}
            onChange={(e) => handlePersonNumberChange(e, i)}
            required
          />
        </div>
      );
    }
    return fields;
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, image: file, imagePreview: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const calculateCashback = () => {
    if (formData.totalTicketPrice) {
      const totalTicketPrice = parseFloat(formData.totalTicketPrice);
      if (!isNaN(totalTicketPrice) && totalTicketPrice > 0) {
        const cashback = Math.random() * (20 - 10) + 10; // Generate random cashback between 10% and 20%
        const cashbackPercentage = cashback.toFixed(2); // Keep two decimal places
        const discountedPrice = (totalTicketPrice * cashbackPercentage) / 100;
        setCashbackPercentage(cashbackPercentage);
        setFormData({
          ...formData,
          discountedPrice: discountedPrice.toFixed(2),
        });
        setCashbackClicked(true); // Disable the button after calculating cashback
        setShowCashbackCard(true); // Show the cashback card
      } else {
        setCashbackPercentage(0); // Reset cashback percentage if input is invalid
        setFormData({ ...formData, discountedPrice: "" });
      }
    } else {
      setCashbackPercentage(0); // Reset cashback percentage if no input
      setFormData({ ...formData, discountedPrice: "" });
    }
  };

  const validateForm = () => {
    let newErrors = {};
    let valid = true;
    if (!formData.fullname) {
      newErrors.fullname = "Fullname is required";
      valid = false;
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
      valid = false;
    }
    if (!formData.movieName) {
      newErrors.movieName = "Movie Name is required";
      valid = false;
    }
    if (!formData.language) {
      newErrors.language = "Language is required";
      valid = false;
    }
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required";
      valid = false;
    }
    if (!formData.totalTicketPrice) {
      newErrors.totalTicketPrice = "Total Ticket Price is required";
      valid = false;
    }
    if (!formData.image) {
      newErrors.image = "Image is required";
      valid = false;
    }
    formData.persons.forEach((person, index) => {
      if (!person.name) {
        newErrors[`personName${index + 1}`] = "Name is required";
        valid = false;
      }
      if (!person.number) {
        newErrors[`personNumber${index + 1}`] = "Number is required";
        valid = false;
      }
    });
    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("Submitting...");

    if (!validateForm()) {
      setMessage("Please fill out all required fields.");
      return;
    }

    const submissionData = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === "persons") {
        formData.persons.forEach((person, index) => {
          if (person && person.name && person.number) {
            submissionData.append(`person${index + 1}Name`, person.name);
            submissionData.append(`person${index + 1}Number`, person.number);
          }
        });
      } else if (key === "image") {
        if (formData.image) {
          submissionData.append("image", formData.image, formData.image.name);
          submissionData.append("imageMimeType", formData.image.type);
        }
      } else if (key !== "imagePreview") {
        submissionData.append(key, formData[key]);
      }
    });

    try {
      const response = await fetch(scriptURL, {
        method: "POST",
        body: submissionData,
      });
      if (response.ok) {
        setMessage(
          "To avail your cashback, please share the link on WhatsApp.!"
        );
        setFormData({
          fullname: "",
          email: "",
          movieName: "",
          language: "",
          tickets: "1",
          phoneNumber: "",
          totalTicketPrice: "",
          discountedPrice: "",
          image: null,
          imagePreview: null,
          persons: [],
          rating: 0,
          reviewMessage: "",
        });
        setSubmitted(true);
        setCashbackClicked(false); // Reset cashback button for new form submission
        setShowCashbackCard(false); // Hide cashback card after submission
        // Scroll to the form section after submission
        const formElement = document.getElementById("form-section");
        if (formElement) {
          formElement.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        setMessage("Failed to submit. Please try again.");
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };
  const handleShareWhatsApp = () => {
    window.location.href =
      "https://api.whatsapp.com/send?text=Here%20is%20the%20initiate%20offer%20from%20film%20producers%20to%20bring%20audience%20to%20theatre.%20Get%20reward,%20after%20watching%20the%20movie.%20Show%20your%20ticket%20details%20and%20avail%20the%20guaranteed%20prize%20money.%20Open%20the%20link%20and%20it%27ll%20take%20two%20mins%20to%20get%20the%20reward.%20www.filmproducers.in";
  };

  const handleRatingChange = (value) => {
    setFormData({ ...formData, rating: value });
  };

  return (
    <div className="container ">
      <br />
      <h2 className="form-heading" style={{ color: "#fff" }}>
        Fill Your Details and Get a Chance to Win Cashback
      </h2>
      <div
        className="alert alert-warning"
        role="alert"
        style={{ color: "#000", fontFamily: "monospace" }}
      >
        Please ensure the accuracy of the provided name and mobile number.
        Cashback will be credited based on the provided details.
      </div>
      <br />
      <div className="mx-auto">
        <div className="card-body">
          {submitted ? (
            <div>
              <div
                className="alert alert-success mt-3"
                style={{ color: "#000", fontFamily: "monospace" }}
              >
                {" "}
                To avail your cashback, please share this link with 10 members
                and 5 groups in your WhastApp:
              </div>
              <button
                type="button"
                className="btn btn-success wavy"
                onClick={handleShareWhatsApp}
              >
                Share on WhatsApp
              </button>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label1">
                  Movie Name <span>Requried</span>
                </label>

                <input
                  name="movieName"
                  type="text"
                  className={`form-control ${
                    errors.movieName ? "is-invalid" : ""
                  }`}
                  placeholder="Movie Name"
                  value={formData.movieName}
                  onChange={handleChange}
                  required
                />
                {errors.movieName && (
                  <div className="invalid-feedback">{errors.movieName}</div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label1">
                  Language Watched <span>Requried</span>
                </label>
                <input
                  name="language"
                  type="text"
                  className={`form-control ${
                    errors.language ? "is-invalid" : ""
                  }`}
                  placeholder="Language Watched"
                  value={formData.language}
                  onChange={handleChange}
                  required
                />
                {errors.language && (
                  <div className="invalid-feedback">{errors.language}</div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label1">
                  Name <span>Requried</span>
                </label>
                <input
                  name="fullname"
                  type="text"
                  className={`form-control ${
                    errors.fullname ? "is-invalid" : ""
                  }`}
                  placeholder="Fullname"
                  value={formData.fullname}
                  onChange={handleChange}
                  required
                />
                {errors.fullname && (
                  <div className="invalid-feedback">{errors.fullname}</div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label1">
                  Phone Number <span>Requried</span>
                </label>
                <input
                  name="phoneNumber"
                  type="tel"
                  className={`form-control ${
                    errors.phoneNumber ? "is-invalid" : ""
                  }`}
                  placeholder="Phone Number"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
                {errors.phoneNumber && (
                  <div className="invalid-feedback">{errors.phoneNumber}</div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label1">
                  Email <span>Requried</span>
                </label>
                <input
                  name="email"
                  type="email"
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label1">
                  No of Ticket's booked <span>Requried</span>
                </label>
                <select
                  name="tickets"
                  className="form-control"
                  value={formData.tickets}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      tickets: e.target.value,
                      persons: [],
                    })
                  }
                >
                  {[...Array(6).keys()].map((i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </select>
              </div>
              {generatePersonFields()}
              <div className="mb-3">
                <label className="form-label1">
                  Price of Ticket <span>Requried</span>
                </label>
                <input
                  name="totalTicketPrice"
                  type="number"
                  className={`form-control ${
                    errors.totalTicketPrice ? "is-invalid" : ""
                  }`}
                  placeholder="Total Ticket Price"
                  min="1"
                  max="2000"
                  value={formData.totalTicketPrice}
                  onChange={handleChange}
                  required
                />
                {errors.totalTicketPrice && (
                  <div className="invalid-feedback">
                    {errors.totalTicketPrice}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label1">
                  Ticket photo <span>Requried</span>
                </label>
                <input
                  name="image"
                  type="file"
                  className={`form-control ${errors.image ? "is-invalid" : ""}`}
                  onChange={handleImageChange}
                />
                {formData.imagePreview && (
                  <div className="mt-3">
                    <img
                      src={formData.imagePreview}
                      alt="Preview"
                      className="img-fluid"
                    />
                  </div>
                )}
                {errors.image && (
                  <div className="invalid-feedback">{errors.image}</div>
                )}
              </div>
              <button
                type="button"
                className="btn mb-3"
                onClick={calculateCashback}
                disabled={cashbackClicked}
              >
                Get Cashback
              </button>
              <div
                className={`card ${showCashbackCard ? "is-flipped" : ""}`}
                onClick={() => setShowCashbackCard(!showCashbackCard)}
              >
                <div className="card-front"></div>
                <div>
                  <p id="DiscountedPrice">
                    Discounted Price: {formData.discountedPrice}
                  </p>
                  <p>Thank you for <br></br>participating!!</p>
                  {/* <p>Congratulations!!!</p> */}
                </div>
              </div>
              {/* ratings and review */}
              <div className="mb-3">
                <label className="form-label">Rate The Movie</label>
                <p className="ml-2">{formData.rating}</p>
                <Rating
                  initialRating={formData.rating}
                  emptySymbol={
                    <i className="far fa-star" style={{ color: "#ffc107" }} />
                  }
                  fullSymbol={
                    <i className="fas fa-star" style={{ color: "#ffc107" }} />
                  }
                  onChange={handleRatingChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Review Message</label>
                <textarea
                  name="reviewMessage"
                  className="form-control"
                  placeholder="Your Review Message"
                  value={formData.reviewMessage}
                  onChange={handleChange}
                  rows={3}
                />
              </div>

              <div className="tacbox">
                <input id="checkbox" type="checkbox" required />
                <label htmlFor="checkbox">
                  I agree to the{" "}
                  <a href="#" id="termsAndC" onClick={showModal}>
                    Terms and Conditions
                  </a>
                </label>
              </div>

              <button
                type="submit"
                className="btn btn-primary btn-animated submit-btn"
              >
                Submit
              </button>
              {message && (
                <div className="mt-3 alert alert-info">{message}</div>
              )}
            </form>
          )}

          {/* TERMS AND CONDITIONS MODAL */}

          <div
            className={`modal ${isModalVisible ? "d-block" : "d-none"}`}
            tabIndex="-1"
            role="dialog"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Terms & Conditions</h5>
                  <button type="button" className="close" onClick={closeModal}>
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    The cashback offer is valid only for users who complete the
                    form and share the link on WhatsApp.
                  </p>
                  <p>
                    The cashback percentage will be calculated randomly between
                    10% and 20% of the total ticket price.
                  </p>
                  <p>
                    Please ensure all required fields are filled out correctly
                    to avail the cashback offer.
                  </p>
                  <p>
                    The offer is subject to change without prior notice. Terms
                    and conditions apply.
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-close"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormDataProd;
