import React, { useEffect } from "react";
import "./Scard.css";

const Scard = () => {
  useEffect(() => {
    const cards = document.querySelectorAll(".card");

    const revealCards = () => {
      cards.forEach((card, index) => {
        if (isElementInViewport(card)) {
          card.classList.add("reveal");
        }
      });
    };

    const isElementInViewport = (element) => {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    // Attach scroll event listener
    window.addEventListener("scroll", revealCards);

    // Initial check on component mount
    revealCards();

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", revealCards);
    };
  }, []);

  return (
    <div>
      <div className="sCardscontainer">
        <h1>How to Get Your Cashback</h1>
        <div className="wrapper">
          <div className="card">
            <h3 className="card-title attractive-title">Step 1:</h3>
            <h3 className="card-subtitle" style={{ color: "#fff" }}>
              Fill Out the Form
            </h3>
            <p className="card-content">
              Fill out the form to start earning exciting cashback on every
              movie ticket you watch in theatres.
            </p>
          </div>
          <div className="card">
            <h3 className="card-title">Step 2:</h3>
            <h3 className="card-subtitle" style={{ color: "#fff" }}>
              Confirmation of Automated voice call
            </h3>
            <p className="card-content">
              Receive a confirmation Automated Voice call from us to participate in a fun quiz to
              unlock additional rewards.
            </p>
          </div>
          <div className="card">
            <h3 className="card-title">Step 3:</h3>
            <h3 className="card-subtitle" style={{ color: "#fff" }}>
              Enjoy Your Cashback
            </h3>
            <p className="card-content">
              Once confirmed, your cashback will be credited to your account.
              Enjoy your rewards and the movies!
            </p>
            <button className="card-btn">Learn More</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scard;
