import React from "react";
import "./MovieCardOne.css"; // Assuming you have a CSS file for styles

const MovieCardOne = () => {
  return (
    <div>
      {/* First section */}
      <section>
        <div
          className="movie-slide"
          style={{ background: "#000", color: "#fff" }}
        >
          <div className="container-title">
            Upcoming&nbsp;<span style={{ color: "#f93" }}>Movie</span>
          </div>
          <div className="movie-box">
            <div className="movie-info">
              <img
                alt="movie poster"
                src="https://i.pinimg.com/originals/8c/59/8e/8c598e23041ab30f831e8ae8ecb3ac8a.jpg"
              />
              <div className="movie-name">KALKI 2898AD</div>
              <div className="review">
                <i className="fa-solid fa-heart"></i>9.5/10
              </div>
            </div>
            <div className="movie-info">
              <img
                alt="movie poster"
                src="https://i0.wp.com/moviegalleri.net/wp-content/uploads/2023/05/NTR30-Devara-Movie-First-Look-Poster-HD.jpg?ssl=1"
              />
              <div className="movie-name">DEVARA</div>
              <div className="review">
                <i className="fa-solid fa-heart"></i> NA  /10
              </div>
            </div>
            <div className="movie-info">
              <img
                alt="movie poster"
                src="https://i0.wp.com/moviegalleri.net/wp-content/uploads/2023/04/Allu-Arjun-Pushpa-2-First-Look-HD-Poster.jpg?ssl=1"
              />
              <div className="movie-name">PUSHPA 2</div>
              <div className="review">
                <i className="fa-solid fa-heart"></i>NA  /10
              </div>
            </div>
            <div className="movie-info">
              <img
                alt="movie poster"
                src="https://i0.wp.com/moviegalleri.net/wp-content/uploads/2023/03/Game-Changer-Movie-Ram-Charan-Birthday-Wishes-Poster-HD.jpg?ssl=1"
              />

              <div className="movie-name">GAME CHANGER</div>
              <div className="review">
                <i className="fa-solid fa-heart"></i>NA  /10
              </div>
            </div>
            <div className="movie-info">
              <img
                alt="movie poster"
                src="https://www.behindwoods.com/tamil-movies/indian-2/stills-photos-pictures/indian-2-stills-photos-pictures-04.jpg"
              />

              <div className="movie-name">INDIAN 2</div>
              <div className="review">
                <i className="fa-solid fa-heart"></i>NA  /10
              </div>
            </div>
            <div className="movie-info">
              <img
                alt="movie poster"
                src="https://upload.wikimedia.org/wikipedia/en/0/0c/OG_Poster.jpg"
              />
              <div className="movie-name">OG</div>
              <div className="review">
                <i className="fa-solid fa-heart"></i>NA  /10
              </div>
            </div>
          </div>
        </div>
        {/* <div className="all-btn">
          <a href="#" style={{ color: "white", textDecorationLine: "none" }}>
            ALL MOVIES
          </a>
        </div> */}
      </section>
    </div>
  );
};

export default MovieCardOne;
