import React, { useEffect, useState } from "react";
import "../AboutUS/AboutUs.css";
import hero from "../AboutUS/file.png";

const AboutUs = ({ scrollToForm }) => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    // Start typing animation after component mounts
    const timer = setTimeout(() => {
      setShowText(true);
    }, 500); // Initial delay before animation starts

    return () => clearTimeout(timer); // Clean up timer on unmount
  }, []);

  return (
    <div className="aboutUsSection">
      <div className="overlay"></div>
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-8 col-md-6 col-12 d-flex align-items-center justify-content-md-start justify-content-center order-md-1 order-2">
            <div className="aboutUsContent text-md-left text-center">
              <h5 className="aboutCardTitle">Who are we?</h5>
              <p className={`cardText ${showText ? "typingAnimation" : ""}`}>
                We are the Film Producers Council, dedicated to bringing
                audiences back to theaters. Enjoy the unique theater experience
                and earn cashback rewards on movie tickets.
              </p>
              <button className="btnAboutUs" onClick={scrollToForm}>
                <span className="btnAboutUsText">Get Cashback</span>
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 d-flex align-items-center justify-content-center order-md-2 order-1">
            <img src={hero} alt="About Us" className="aboutUsImage" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
